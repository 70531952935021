import React from "react";
import { Link } from "react-router-dom";
import ScrollToTop from "../ScrollToTop";

const Footer = () => {
    return (
        <>
            <ScrollToTop />
            <footer className="page_footer ds ms s-py-50 s-pb-lg-60 s-pb-xl-60" style={{ marginTop: "30px" }}>
                <div className="container">
                    <div className="row align-center">
                        <div
                            className="col-xl-3 col-lg-3 text-center text-lg-left"
                            data-animation="fadeInUp"
                        >
                            <a href="index.html" className="logo">
                                <Link to="/"><img src="images/astro-logo.png" alt="img" /></Link>

                            </a>
                            <div className="divider-60 divider-lg-0" />
                        </div>
                        <div
                            className="col-xl-4 col-lg-4 align-self-start text-center text-lg-left"
                            data-animation="fadeInUp"
                        >
                            <div className="widget widget_nav_menu">
                                <h3>Services</h3>
                                <ul className="menu">
                                    <li className="menu-item">
                                        <Link to="/services">Digitalised Analysis of Horoscope</Link>
                                    </li>
                                    <li className="menu-item">
                                        <Link to="/services">NLP (Neurolinguistic programming)</Link>
                                    </li>
                                    <li className="menu-item">
                                        <Link to="/services"> Numerology</Link>
                                    </li>
                                    <li className="menu-item">
                                        <Link to="/services"> Vastu Visit</Link>
                                    </li>
                                    <li className="menu-item">
                                        <Link to="/services"> Tarot Card</Link>
                                    </li>
                                    <li className="menu-item">
                                        <Link to="/services"> View All Services</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="divider-60 divider-lg-0" />
                        </div>
                        <div
                            className="col-xl-2 col-lg-2 align-self-start text-center text-lg-left"
                            data-animation="fadeInUp"
                        >
                            <div className="widget widget_nav_menu">
                                <h3>Quick Links</h3>
                                <ul className="menu">
                                    <li className="menu-item">
                                        <Link to="/about-us" href="about.html">About</Link>
                                    </li>
                                    <li className="menu-item">
                                        <Link to="/disclaimer">Disclaimer</Link>
                                    </li>
                                    <li className="menu-item">
                                        <Link to="/privacy-policy">Privacy Policy</Link>
                                    </li>
                                    <li className="menu-item">
                                        <Link to="/terms-condition">Terms & Conditions</Link>
                                    </li>
                                    {/* <li className="menu-item">
                                        <Link to="/blog">Blog</Link>
                                    </li> */}
                                    <li className="menu-item">
                                        <Link to="/contact-us">Contact Us</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="divider-60 divider-lg-0" />
                        </div>
                        <div
                            className="col-xl-3 col-lg-3 align-self-start text-center text-lg-left"
                            data-animation="fadeInUp"
                        >
                            <div className="widget widget_text">
                                <h3>Contact Info</h3>
                                <p>
                                    B-106 , 201 Dwarkadhish Apartment Rohini Sector 26, New Delhi-110085
                                </p>
                                <p>
                                    info@paraastro.com
                                </p>
                                <p>
                                    +91-9818194431
                                </p>
                                <p className="social-icons">
                                    <a
                                        href="https://www.facebook.com/"
                                        className="fa fa-facebook"
                                        title="facebook"
                                    />
                                    <a
                                        href="https://twitter.com/"
                                        className="fa fa-twitter"
                                        title="twitter"
                                    />
                                    <a
                                        href="https://www.instagram.com/"
                                        className="fa fa-instagram"
                                        title="instagram"
                                    />
                                    <a
                                        href="https://www.linkedin.com/"
                                        className="fa fa-linkedin"
                                        title="linkedin"
                                    />
                                    <a
                                        href="https://www.youtube.com/"
                                        className="fa fa-youtube-play"
                                        title="youtube"
                                    />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <section className="page_copyright ds ms s-py-25 s-bordertop-container">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12 text-center">
                            <p>
                                © Copyright <span className="copyright_year">2020</span> All Rights
                                Reserved
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Footer;