import React from "react";

const TermsCondition = () => {
    return (
        <>
            <div className="about-us-page container" >
                <h3>Terms & Conditions</h3>
                <p><b>Introduction.</b></p>
                <p>These Terms and Conditions (these “Terms” or these “Terms and Conditions”) contained herein on this webpage, shall
                    govern your use of this website, including all pages within this website (collectively referred to herein below as
                    this “Website”). These Terms apply in full force and effect to your use of this Website and by using this Website,
                    you expressly accept all terms and conditions contained herein in full. You must not use this Website if you have
                    any objection to any of these Terms and Conditions.</p>
                <p>This Website is not for use by any minors (defined as those who are not at least 18 years of age), and you must
                    not use this Website if you a minor.</p>
                <p><b>Intellectual Property Rights.</b></p>
                <p>Other than the content you own, which you may have opted to include on this Website, under these Terms, This
                    Website and/or its licensors own all rights to the intellectual property and material contained in this Website,
                    and all such rights are reserved.</p>
                <p>You are granted a limited license only, subject to the restrictions provided in these Terms, for purposes of
                    viewing the material contained on this Website.</p>
                <p><b>Restrictions.</b></p>
                <p>You are expressly and emphatically restricted from all of the following:</p>
                <ol>
                    <li>Publishing any Website material in any media;</li>
                    <li>Selling, sublicensing and/or otherwise commercializing any Website material;</li>
                    <li>Publicly performing and/or showing any Website material;</li>
                    <li>Using this Website in any way that is, or maybe, damaging to this Website;</li>
                    <li>Using this Website in any way that impacts user access to this Website;</li>
                    <li>using this Website contrary to applicable laws and regulations, or in a way that causes, or may cause, harm to the Website,
                        or to any person or business entity;</li>
                    <li>Engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this Website,
                        or while using this Website;</li>
                    <li>Using this Website to engage in any advertising or marketing;</li>
                </ol>
                <p>Certain areas of this Website are restricted from access by you and This Website may further restrict access by you to any areas
                    of this Website, at any time, in its sole and absolute discretion. Any user ID and password you may have for this Website are
                    confidential and you must maintain the confidentiality of such information.</p>
                <p><b>Your Content.</b></p>
                <p>In these Terms and Conditions, “Your Content” shall mean any audio, video, text, images or other material you choose to display
                    on this Website. With respect to Your Content, by displaying it, you grant This Website a non-exclusive, worldwide, irrevocable,
                    royalty-free, sublicensable license to use, reproduce, adapt, publish, translate and distribute it in any and all media.</p>
                <p>Your Content must be your own and must not be infringing on any third party’s rights. This Website reserves the right to remove
                    any of Your Content from this Website at any time, and for any reason, without notice.</p>
                <p><b>No warranties.</b></p>
                <p>This Website is provided “as is,” with all faults, and This Website makes no express or implied representations or warranties,
                    of any kind related to this Website or the materials contained on this Website. Additionally, nothing contained on this Website
                    shall be construed as providing consult or advice to you.</p>
                <p><b>Limitation of liability.</b></p>
                <p>In no event shall This Website, nor any of its officers, directors, and employees, be liable to you for anything arising out of or
                    in any way connected with your use of this Website, whether such liability is under contract, tort or otherwise, and This Website,
                    including its officers, directors, and employees shall not be liable for any indirect, consequential or special liability arising
                    out of or in any way related to your use of this Website.</p>
                <p><b>Indemnification.</b></p>
                <p>You hereby indemnify to the fullest extent This Website from and against any and all liabilities, costs, demands, causes of action,
                    damages and expenses (including reasonable attorney’s fees) arising out of or in any way related to your breach of any of the
                    provisions of these Terms.</p>
                <p><b>Severability.</b></p>
                <p>If any provision of these Terms is found to be unenforceable or invalid under any applicable law, such unenforceability or invalidity
                    shall not render these Terms unenforceable or invalid as a whole, and such provisions shall be deleted without affecting the
                    remaining provisions herein.</p>
                <p><b>Variation of Terms.</b></p>
                <p>This Website is permitted to revise these Terms at any time as it sees fit, and by using this Website you are expected to review such
                    Terms on a regular basis to ensure you understand all terms and conditions governing the use of this Website.</p>
                <p><b>Assignment.</b></p>
                <p>This Website shall be permitted to assign, transfer, and subcontract its rights and/or obligations under these Terms without any 
                    notification or consent required. However, .you shall not be permitted to assign, transfer, or subcontract any of your rights 
                    and/or obligations under these Terms.</p>
                <p><b>Entire Agreement.</b></p>
                <p>These Terms, including any legal notices and disclaimers contained on this Website, constitute the entire agreement between This Website and
                     you in relation to your use of this Website and supersede all prior agreements and understandings with respect to the same.</p>
                <p><b>Governing Law & Jurisdiction.</b></p>
                <p>These Terms will be governed by and construed in accordance with the laws of our country, and you submit to the non-exclusive jurisdiction
                     of the country and federal courts located in our country for the resolution of any disputes.</p>

            </div>
        </>
    )
}

export default TermsCondition;