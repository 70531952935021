import React from "react";

const Blog = () => {
    return (
        <>
            <h1>Hello Blog</h1>
        </>
    )
}

export default Blog;