import React from "react";
import Services from "../Services/Services";
import Testimonials from "../Testimonials/Testimonials";
import { Link } from "react-router-dom";

const HomePage = () => {
  return (
    <>
      <section className="ds section-home s-pb-lg-80">
        <div className="container">
          {/* <div className="divider-50 divider-xl-65" /> */}
          <div className="row align-center">
            <div className="col-lg-6 col-xl-5 order-lg-1 order-2 text-center text-md-left">
              <div className="divider-60 divider-lg-0" />
              <h5 className="special-heading bold mb-2 text-white">
                <span>Sun Signs</span>
              </h5>
              <p>
                There are 12 signs of the zodiac, each one taking up 30 degrees
                in your birth chart, manking a complete 360 degree circle
              </p>
              {/* <div className="divider-35 divider-xl-10" /> */}
              <div className="line line-left" />
              <div className="divider-40 divider-xl-20" />
              <h3 className="special-heading fw-500 mb-2 color-main2 big-size">
                <span>Ultimate Guide</span>
              </h3>
              <h3 className="special-heading fw-500 text-white fs-50">
                <span>To Astrology</span>
              </h3>
              <div className="divider-35 divider-xl-20" />
              <a
                href="https://wa.me/9818194431"
                target="_blank"
                className="btn btn-maincolor"
              >
                Let’s Chat
              </a>
            </div>
            <div className="col-lg-6 col-xl-7 order-lg-2 order-1 text-center text-lg-right">
              <img
                className="horoscope-image"
                src="images/horoscope.png"
                alt="img"
              />
            </div>
          </div>
        </div>
      </section>
      <section id="about" className="ds">
        <div className="container">
          <div className="row align-center">
            <div className="col-lg-6">
              <img src="images/about-img.png" alt="img" />
            </div>
            <div className="col-lg-6 text-center text-md-left">
              <div className="divider-60 divider-lg-0 " />
              <p className="text-white text-center text-md-left fs-20 line-right mb-0">
                <span>About Us</span>
              </p>
              <h3 className="special-heading mt-2 fs-60 text-center text-md-left">
                <span>We Are Welcome</span>
              </h3>
              <div className="divider-35" />
              <p>
                We have the World Famous Astrologers on the Best Astrology
                Website in India, practising both Indian Astrology and Western
                Astrology for astrology today.
              </p>
              <p>
                They will provide the best free horoscope astrology to you by
                analysing your birth chart and your astrology sign.
              </p>
              <div className="shortcode-simple-counter counter-layout-2">
                <div className="counter_wrap justify-content-md-start">
                  <div className="counter-wrap">
                    <h2
                      className="counter counter-size"
                      data-from={0}
                      data-to={25}
                      data-speed={1000}
                    >
                      25
                    </h2>
                  </div>
                  <p className="counter-text ">
                    <span className="counter-add">
                      Years Of
                      <br />
                      <span className="color-dark fw-500">Experience</span>
                    </span>
                  </p>
                </div>
              </div>
              <div className="divider-30 divider-xl-20" />
              <Link
                to="/book-appoinment"
                className="btn btn-outline-maincolor btn-medium"
              >
                <i class="fa fa-calendar" style={{ marginRight: "5px" }}></i>
                Book Appointment
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section
        id="forecasts"
        className="ds section-forecast s-overlay s-pt-90 s-pb-30 c-gutter-60 c-mb-60"
      >
        <div className="container">
          <div className="row">
            <div className="col-12 text-center mb-0">
              <h3 className="text-center mt-0 mb-2 special-heading fs-60 color-main">
                <span>Choose Your Zodiac Sign</span>
              </h3>
              <div className="line" />
              <p className="text-center fs-20 mt-4">
                <span>
                  What’s Your Sign? Read Your Daily <br />
                  Horoscope Today
                </span>
              </p>
              <div className="divider-40 divider-xl-60" />
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-6">
              <a
                href="https://www.hindustantimes.com/astrology/aries-horoscope"
                target="_blank"
              >
                <div className="vertical-item text-center  content-padding corner-box">
                  <div className="item-content">
                    <div>
                      <img src="images/icons8-aries-50.png" />
                    </div>
                    <h5 className="mt-4 mt-lg-3 fs-20 links-maincolor2">
                      <a
                        href="https://www.hindustantimes.com/astrology/aries-horoscope"
                        target="_blank"
                      >
                        Aries
                      </a>
                    </h5>
                    <p>Mar 21 - Apr 19</p>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-6">
              <a
                href="https://www.hindustantimes.com/astrology/taurus-horoscope"
                target="_blank"
              >
                <div className="vertical-item text-center  content-padding corner-box">
                  <div className="item-content">
                    <div>
                      <img src="images/icons8-taurus-50.png" />
                    </div>
                    <h5 className="mt-4 mt-lg-3 fs-20 links-maincolor2">
                      <a
                        href="https://www.hindustantimes.com/astrology/taurus-horoscope"
                        target="_blank"
                      >
                        Taurus
                      </a>
                    </h5>
                    <p>Apr 20 - May 20</p>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-6">
              <a
                href="https://www.hindustantimes.com/astrology/gemini-horoscope"
                target="_blank"
              >
                <div className="vertical-item text-center  content-padding corner-box">
                  <div className="item-content">
                    <div>
                      <img src="images/icons8-gemini-50.png" />
                    </div>
                    <h5 className="mt-4 mt-lg-3 fs-20 links-maincolor2">
                      <a
                        href="https://www.hindustantimes.com/astrology/gemini-horoscope"
                        target="_blank"
                      >
                        Gemini
                      </a>
                    </h5>
                    <p>May 21 - Jun 20</p>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-6">
              <a
                href="https://www.hindustantimes.com/astrology/cancer-horoscope"
                target="_blank"
              >
                <div className="vertical-item text-center  content-padding corner-box">
                  <div className="item-content">
                    <div>
                      <img src="images/icons8-cancer-50.png" />
                    </div>
                    <h5 className="mt-4 mt-lg-3 fs-20 links-maincolor2">
                      <a
                        href="https://www.hindustantimes.com/astrology/cancer-horoscope"
                        target="_blank"
                      >
                        Cancer
                      </a>
                    </h5>
                    <p>Jun 21 - Jul 22</p>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-6">
              <a
                href="https://www.hindustantimes.com/astrology/leo-horoscope"
                target="_blank"
              >
                <div className="vertical-item text-center  content-padding corner-box">
                  <div className="item-content">
                    <div>
                      <img src="images/icons8-leo-50.png" />
                    </div>
                    <h5 className="mt-4 mt-lg-3 fs-20 links-maincolor2">
                      <a
                        href="https://www.hindustantimes.com/astrology/leo-horoscope"
                        target="_blank"
                      >
                        Leo
                      </a>
                    </h5>
                    <p>Jul 23 - Aug 22</p>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-6">
              <a
                href="https://www.hindustantimes.com/astrology/virgo-horoscope"
                target="_blank"
              >
                <div className="vertical-item text-center  content-padding corner-box">
                  <div className="item-content">
                    <div>
                      <img src="images/icons8-virgo-50.png" />
                    </div>
                    <h5 className="mt-4 mt-lg-3 fs-20 links-maincolor2">
                      <a
                        href="https://www.hindustantimes.com/astrology/virgo-horoscope"
                        target="_blank"
                      >
                        Virgo
                      </a>
                    </h5>
                    <p>Aug 23 - Sep 22</p>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-6">
              <a
                href="https://www.hindustantimes.com/astrology/libra-horoscope"
                target="_blank"
              >
                <div className="vertical-item text-center  content-padding corner-box">
                  <div className="item-content">
                    <div>
                      <img src="images/icons8-libra-50.png" />
                    </div>
                    <h5 className="mt-4 mt-lg-3 fs-20 links-maincolor2">
                      <a
                        href="https://www.hindustantimes.com/astrology/libra-horoscope"
                        target="_blank"
                      >
                        Libra
                      </a>
                    </h5>
                    <p>Sep 23 - Oct 22</p>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-6">
              <a
                href="https://www.hindustantimes.com/astrology/scorpio-horoscope"
                target="_blank"
              >
                <div className="vertical-item text-center  content-padding corner-box">
                  <div className="item-content">
                    <div>
                      <img src="images/icons8-scorpio-50.png" />
                    </div>
                    <h5 className="mt-4 mt-lg-3 fs-20 links-maincolor2">
                      <a
                        href="https://www.hindustantimes.com/astrology/scorpio-horoscope"
                        target="_blank"
                      >
                        Scorpio
                      </a>
                    </h5>
                    <p>Oct 23 - Nov 21</p>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-6">
              <a
                href="https://www.hindustantimes.com/astrology/sagittarius-horoscope"
                target="_blank"
              >
                <div className="vertical-item text-center  content-padding corner-box">
                  <div className="item-content">
                    <div>
                      <img src="images/icons8-sagittarius-50.png" />
                    </div>
                    <h5 className="mt-4 mt-lg-3 fs-20 links-maincolor2">
                      <a
                        href="https://www.hindustantimes.com/astrology/sagittarius-horoscope"
                        target="_blank"
                      >
                        Sagittarius
                      </a>
                    </h5>
                    <p>Nov 22 - Dec 21</p>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-6">
              <a
                href="https://www.hindustantimes.com/astrology/capricorn-horoscope"
                target="_blank"
              >
                <div className="vertical-item text-center  content-padding corner-box">
                  <div className="item-content">
                    <div>
                      <img src="images/icons8-capricorn-50.png" />
                    </div>
                    <h5 className="mt-4 mt-lg-3 fs-20 links-maincolor2">
                      <a
                        href="https://www.hindustantimes.com/astrology/capricorn-horoscope"
                        target="_blank"
                      >
                        Capricornius
                      </a>
                    </h5>
                    <p>Dec 22 - Jan 19</p>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-6">
              <a
                href="https://www.hindustantimes.com/astrology/aquarius-horoscope"
                target="_blank"
              >
                <div className="vertical-item text-center  content-padding corner-box">
                  <div className="item-content">
                    <div>
                      <img src="images/icons8-aquarius-50.png" />
                    </div>
                    <h5 className="mt-4 mt-lg-3 fs-20 links-maincolor2">
                      <a
                        href="https://www.hindustantimes.com/astrology/aquarius-horoscope"
                        target="_blank"
                      >
                        Aquarius
                      </a>
                    </h5>
                    <p>Jan 20 - Feb 18</p>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-6">
              <a
                href="https://www.hindustantimes.com/astrology/pisces-horoscope"
                target="_blank"
              >
                <div className="vertical-item text-center  content-padding corner-box">
                  <div className="item-content">
                    <div>
                      <img src="images/icons8-pisces-50.png" />
                    </div>
                    <h5 className="mt-4 mt-lg-3 fs-20 links-maincolor2">
                      <a
                        href="https://www.hindustantimes.com/astrology/pisces-horoscope"
                        target="_blank"
                      >
                        Pisces
                      </a>
                    </h5>
                    <p>Feb 19 - Mar 20</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
      <Services />
      <Testimonials />
      <section id="blog" className="ds bs pattern">
        <div className="container">
          <div className="row">
            {/* <div className="col-xl-5 col-lg-6">
                            <p className="text-white text-center text-md-left fs-20 line-right mb-0">
                                <span>Blog</span>
                            </p>
                            <h3 className="special-heading mt-2 fs-60 text-center text-md-left">
                                <span>Last Materials In Blog</span>
                            </h3>
                        </div> */}
            {/* <div className="col-xl-7 col-lg-6 text-center text-md-left">
                            <p className="text-center text-md-left  mt-4 mt-lg-0">
                                <span>
                                    Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
                                    officia deserunt mollit anim id est laborum.
                                </span>
                            </p>
                            <div className="divider-35" />
                            <a
                                href="blog-right.html"
                                className="btn btn-outline-maincolor btn-medium"
                            >
                                Book Appointment
                            </a>
                        </div> */}
            <div className="col-12">
              {/* <div className="shortcode-post-grid-tilled-1">
                                <div className="special-img">
                                    <img src="images/gallery/22.jpg" alt="img" />
                                </div>
                                <article className="vertical-item text-center text-md-left post type-post status-publish format-standard has-post-thumbnail mt-0">
                                    <div className="item-content">
                                        <header className="entry-header">
                                            <h4 className="entry-title mb-3 links-maincolor2">
                                                <a
                                                    className="fs-30"
                                                    href="blog-single-right.html"
                                                    rel="bookmark"
                                                >
                                                    The Secret Revealed – Why Millennials Love Astrology
                                                </a>
                                            </h4>
                                        </header>
                                      
                                        <div className="entry-content mt-20">
                                            <p>
                                                Aged around 25, 26, 27, 28, 29, 30, 31, 32, 33, 34 -
                                                Millennials born 1986 through 1995, in their late twenties and
                                                early thirties, love
                                            </p>
                                        </div>
                                      
                                        <div className="entry-footer flex-row mt-4">
                                            <div className="entry-meta">
                                                <span>
                                                    <a href="blog-single-right.html" rel="bookmark">
                                                        <time
                                                            className="entry-date published updated"
                                                            dateTime="2018-03-18T15:15:12+00:00"
                                                        >
                                                            18 may 2020
                                                        </time>
                                                    </a>
                                                </span>
                                            </div>
                                          
                                            <div className="mt-0 ml-auto">
                                                <a href="blog-single-right.html" className="mt-30 font-2">
                                                    Read Now <i className="fa fa-caret-right color-main ml-2" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                   
                                </article>
                                
                                <article className="vertical-item text-center text-md-left post type-post status-publish format-standard has-post-thumbnail mt-0">
                                    <div className="item-content">
                                        <header className="entry-header">
                                            <h4 className="entry-title mb-3 links-maincolor2">
                                                <a
                                                    className="fs-30"
                                                    href="blog-single-right.html"
                                                    rel="bookmark"
                                                >
                                                    Born 1990-1995? Your Astrology for 2020-2029 is Calling
                                                </a>
                                            </h4>
                                        </header>
                                
                                        <div className="entry-content mt-20">
                                            <p>
                                                The boom in Millennial Astrology (for people born 1990 to
                                                1995) means horoscopes are now known factors for anyone aged
                                                around
                                            </p>
                                        </div>
                                      
                                        <div className="entry-footer flex-row mt-4">
                                            <div className="entry-meta">
                                                <span>
                                                    <a href="blog-single-right.html" rel="bookmark">
                                                        <time
                                                            className="entry-date published updated"
                                                            dateTime="2018-03-18T15:15:12+00:00"
                                                        >
                                                            18 may 2020
                                                        </time>
                                                    </a>
                                                </span>
                                            </div>
                                           
                                            <div className="mt-0 ml-auto">
                                                <a href="blog-single-right.html" className="mt-30 font-2">
                                                    Read Now <i className="fa fa-caret-right color-main ml-2" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </article> 
                            </div> */}

              <div className="divider-60" />
              <div
                className="owl-carousel "
                data-responsive-lg={3}
                data-responsive-md={3}
                data-responsive-sm={2}
                data-responsive-xs={1}
                data-nav="false"
                data-margin={60}
                data-loop="true"
                data-autoplay="true"
              >
                <article className="vertical-item bg-darkblue text-center text-lg-left box-shadow content-padding padding-big post type-post status-publish format-standard has-post-thumbnail">
                  <div className="item-media post-thumbnail">
                    <img src="images/gallery/08.jpg" alt="img" />
                    <div className="media-links">
                      <a className="abs-link" href="blog-single-right.html" />
                    </div>
                  </div>
                  {/* .post-thumbnail */}
                  <div className="item-content">
                    <header className="entry-header">
                      <h4 className="entry-title mb-3 links-maincolor2">
                        <a
                          className="fs-30"
                          href="blog-single-right.html"
                          rel="bookmark"
                        >
                          Study Astrology and Tarot for Yourself –
                        </a>
                      </h4>
                    </header>
                    {/* .entry-header */}
                    <div className="entry-content mt-20">
                      <p>
                        Jupiter is in Capricorn from Tuesday 3rd December 2019.
                      </p>
                    </div>
                    {/* .entry-content */}
                    <div className="entry-footer flex-row mt-4">
                      <div className="entry-meta">
                        <span>
                          <a href="blog-single-right.html" rel="bookmark">
                            <time
                              className="entry-date published updated"
                              dateTime="2018-03-18T15:15:12+00:00"
                            >
                              18 may 2020
                            </time>
                          </a>
                        </span>
                      </div>
                      {/* .entry-meta */}
                      <div className="mt-0 ml-auto">
                        <a
                          href="blog-single-right.html"
                          className="mt-30 font-2"
                        >
                          Read Now{" "}
                          <i className="fa fa-caret-right color-main ml-2" />
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* .item-content */}
                </article>
                {/* #post-## */}
                <article className="vertical-item bg-darkblue text-center text-lg-left box-shadow content-padding padding-big post type-post status-publish format-standard has-post-thumbnail">
                  <div className="item-media post-thumbnail">
                    <img src="images/gallery/09.jpg" alt="img" />
                    <div className="media-links">
                      <a className="abs-link" href="blog-single-right.html" />
                    </div>
                  </div>
                  {/* .post-thumbnail */}
                  <div className="item-content ">
                    <header className="entry-header">
                      <h4 className="entry-title mb-3 links-maincolor2">
                        <a
                          className="fs-30"
                          href="blog-single-right.html"
                          rel="bookmark"
                        >
                          Wrong Birth Time Horoscopes
                        </a>
                      </h4>
                    </header>
                    {/* .entry-header */}
                    <div className="entry-content mt-20">
                      <p>
                        Jupiter is in Capricorn from Tuesday 3rd December 2019.
                      </p>
                    </div>
                    {/* .entry-content */}
                    <div className="entry-footer flex-row mt-4">
                      <div className="entry-meta">
                        <span>
                          <a href="blog-single-right.html" rel="bookmark">
                            <time
                              className="entry-date published updated"
                              dateTime="2018-03-18T15:15:12+00:00"
                            >
                              18 may 2020
                            </time>
                          </a>
                        </span>
                      </div>
                      {/* .entry-meta */}
                      <div className="mt-0 ml-auto">
                        <a
                          href="blog-single-right.html"
                          className="mt-30 font-2"
                        >
                          Read Now{" "}
                          <i className="fa fa-caret-right color-main ml-2" />
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* .item-content */}
                </article>
                {/* #post-## */}
                <article className="vertical-item bg-darkblue text-center text-lg-left box-shadow content-padding padding-big post type-post status-publish format-standard has-post-thumbnail">
                  <div className="item-media post-thumbnail">
                    <img src="images/gallery/10.jpg" alt="img" />
                    <div className="media-links">
                      <a className="abs-link" href="blog-single-right.html" />
                    </div>
                  </div>
                  {/* .post-thumbnail */}
                  <div className="item-content ">
                    <header className="entry-header">
                      <h4 className="entry-title mb-3 links-maincolor2">
                        <a
                          className="fs-30"
                          href="blog-single-right.html"
                          rel="bookmark"
                        >
                          200 Astrology Secrets for 2020
                        </a>
                      </h4>
                    </header>
                    {/* .entry-header */}
                    <div className="entry-content mt-20">
                      <p>
                        Jupiter is in Capricorn from Tuesday 3rd December 2019.
                      </p>
                    </div>
                    {/* .entry-content */}
                    <div className="entry-footer flex-row mt-4">
                      <div className="entry-meta">
                        <span>
                          <a href="blog-single-right.html" rel="bookmark">
                            <time
                              className="entry-date published updated"
                              dateTime="2018-03-18T15:15:12+00:00"
                            >
                              18 may 2020
                            </time>
                          </a>
                        </span>
                      </div>
                      {/* .entry-meta */}
                      <div className="mt-0 ml-auto">
                        <a
                          href="blog-single-right.html"
                          className="mt-30 font-2"
                        >
                          Read Now{" "}
                          <i className="fa fa-caret-right color-main ml-2" />
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* .item-content */}
                </article>
                {/* #post-## */}
                <article className="vertical-item bg-darkblue text-center text-lg-left box-shadow content-padding padding-big post type-post status-publish format-standard has-post-thumbnail">
                  <div className="item-media post-thumbnail">
                    <img src="images/gallery/11.jpg" alt="img" />
                    <div className="media-links">
                      <a className="abs-link" href="blog-single-right.html" />
                    </div>
                  </div>
                  {/* .post-thumbnail */}
                  <div className="item-content">
                    <header className="entry-header">
                      <h4 className="entry-title mb-3 links-maincolor2">
                        <a
                          className="fs-30"
                          href="blog-single-right.html"
                          rel="bookmark"
                        >
                          Study Astrology and Tarot for Yourself –
                        </a>
                      </h4>
                    </header>
                    {/* .entry-header */}
                    <div className="entry-content mt-20">
                      <p>
                        Jupiter is in Capricorn from Tuesday 3rd December 2019.
                      </p>
                    </div>
                    {/* .entry-content */}
                    <div className="entry-footer flex-row mt-4">
                      <div className="entry-meta">
                        <span>
                          <a href="blog-single-right.html" rel="bookmark">
                            <time
                              className="entry-date published updated"
                              dateTime="2018-03-18T15:15:12+00:00"
                            >
                              18 may 2020
                            </time>
                          </a>
                        </span>
                      </div>
                      {/* .entry-meta */}
                      <div className="mt-0 ml-auto">
                        <a
                          href="blog-single-right.html"
                          className="mt-30 font-2"
                        >
                          Read Now{" "}
                          <i className="fa fa-caret-right color-main ml-2" />
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* .item-content */}
                </article>
                {/* #post-## */}
              </div>
            </div>
            {/* <div className="col-12 text-center">
                            <div className="divider-55 divider-xl-20" />
                            <h3 className="text-center mt-0 mb-2 special-heading fs-60 color-main">
                                <span>Purchase Packages</span>
                            </h3>
                            <div className="line" />
                            <p className="text-center fs-20 mt-4">
                                <span>
                                    What’s Your Sign? Read Your Daily <br />
                                    Horoscope Today
                                </span>
                            </p>
                            <div className="divider-40 divider-xl-60" />
                        </div> */}
            {/* <div className="col-lg-4">
                            <div className="pricing-plan price-layout-1 bg-darkgrey">
                                <div className="icon-styled fs-50">
                                    <i className="color-darkgrey ico ico-diamond" />
                                </div>
                                <div className="plan-name">
                                    <div className="divider-25" />
                                    <h3 className="mt-0">Starter</h3>
                                    <div className="plan-description">Starting Business</div>
                                </div>
                                <div className="plan-features text-center">
                                    <ul className="list-bordered">
                                        <li>Ask One Question</li>
                                        <li>Half-Hour Reading</li>
                                        <li>Newborn / Child Reading</li>
                                        <li>Relationship Reading</li>
                                    </ul>
                                </div>
                                <div className="price-wrap">
                                    <div className="wrapper">
                                        <span className="plan-sign">$</span>
                                        <span className="plan-price">25</span>
                                    </div>
                                    <span className="plan-decimals text-left">
                                        Per <br />
                                        Month
                                    </span>
                                </div>
                                <div className="plan-button">
                                    <a href="pricing.html" className="btn btn-medium btn-shop">
                                        Purchase
                                    </a>
                                </div>
                            </div>
                            <div className="divider-60 divider-lg-0" />
                        </div>
                        <div className="col-lg-4">
                            <div className="pricing-plan price-layout-1 cs plan-featured">
                                <div className="icon-styled fs-50">
                                    <i className="text-white ico ico-gem" />
                                </div>
                                <div className="plan-name">
                                    <div className="divider-25" />
                                    <h3 className="mt-0">Pro</h3>
                                    <div className="plan-description">Starting Business</div>
                                </div>
                                <div className="plan-features text-center">
                                    <ul className="list-bordered">
                                        <li>Ask One Question</li>
                                        <li>Half-Hour Reading</li>
                                        <li>Newborn / Child Reading</li>
                                        <li>Relationship Reading</li>
                                    </ul>
                                </div>
                                <div className="price-wrap">
                                    <div className="wrapper">
                                        <span className="plan-sign">$</span>
                                        <span className="plan-price">73</span>
                                    </div>
                                    <span className="plan-decimals text-left">
                                        Per <br />
                                        Month
                                    </span>
                                </div>
                                <div className="plan-button">
                                    <a href="pricing.html" className="btn btn-medium btn-shop">
                                        Purchase
                                    </a>
                                </div>
                            </div>
                            <div className="divider-60 divider-lg-0" />
                        </div>
                        <div className="col-lg-4">
                            <div className="pricing-plan price-layout-1 bg-darkgrey">
                                <div className="icon-styled fs-50">
                                    <i className="color-darkgrey ico ico-gems" />
                                </div>
                                <div className="plan-name">
                                    <div className="divider-25" />
                                    <h3 className="mt-0">Business</h3>
                                    <div className="plan-description">Starting Business</div>
                                </div>
                                <div className="plan-features text-center">
                                    <ul className="list-bordered">
                                        <li>Ask One Question</li>
                                        <li>Half-Hour Reading</li>
                                        <li>Newborn / Child Reading</li>
                                        <li>Relationship Reading</li>
                                    </ul>
                                </div>
                                <div className="price-wrap">
                                    <div className="wrapper">
                                        <span className="plan-sign">$</span>
                                        <span className="plan-price">109</span>
                                    </div>
                                    <span className="plan-decimals text-left">
                                        Per <br />
                                        Month
                                    </span>
                                </div>
                                <div className="plan-button">
                                    <a href="pricing.html" className="btn btn-medium btn-shop">
                                        Purchase
                                    </a>
                                </div>
                            </div>
                        </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default HomePage;
