import React from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";

const Services = () => {
    var settings = {
        dots: true,
        autoplay: false,
        infinite: true,
        arrows: false,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [{
            breakpoint: 800,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: false
            }
        }]
    };
    return (
        <>
            <section
                id="shop"
                className="ds section-shop s-pb-60"
            >
                <div className="container">
                    <div className="row">
                        <h3 className="mt-0 mb-2 special-heading fs-60 color-main">
                            <span>Services</span>
                            <Link to="/services" className='view-all-services-btn'>View All Services</Link>
                        </h3>
                    </div>

                    <div className='row'>
                        <Slider {...settings}>
                            <div className="col-md-4" >
                                <div className="pricing-plan price-layout-1 bg-darkgrey">
                                    <div className="icon-styled fs-50">
                                        <i className="color-darkgrey ico ico-diamond" />
                                    </div>
                                    <div className="plan-name">
                                        <img src="/images/gallery/service-1.jpg" />
                                        <div className="plan-description" style={{ paddingTop: "20px" }}><h5>Digitalised Analysis of Horoscope</h5></div>
                                        <p className='word-limit'>Digitalized analysis of horoscopes involves using computer algorithms and software to interpret astrological charts. By inputting birth details, the software calculates planetary positions and aspects, providing insights into personality traits, life events, and potential challenges. This technology enhances efficiency and accuracy in astrological assessments, offering a modern approach to traditional horoscope analysis.</p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-md-4">
                            <div className="pricing-plan price-layout-1 cs plan-featured">
                                <div className="icon-styled fs-50">
                                    <i className="text-white ico ico-gem" />
                                </div>
                                <div className="plan-name">
                                    <div className="divider-25" />
                                    <h3 className="mt-0">Pro</h3>
                                    <div className="plan-description">Starting Business</div>
                                </div>
                                <div className="plan-features text-center">
                                    <ul className="list-bordered">
                                        <li>Ask One Question</li>
                                        <li>Half-Hour Reading</li>
                                        <li>Newborn / Child Reading</li>
                                        <li>Relationship Reading</li>
                                    </ul>
                                </div>
                                <div className="price-wrap">
                                    <div className="wrapper">
                                        <span className="plan-sign">$</span>
                                        <span className="plan-price">73</span>
                                    </div>
                                    <span className="plan-decimals text-left">
                                        Per <br />
                                        Month
                                    </span>
                                </div>
                                <div className="plan-button">
                                    <a href="pricing.html" className="btn btn-medium btn-shop">
                                        Purchase
                                    </a>
                                </div>
                            </div>
                            <div className="divider-60 divider-lg-0" />
                        </div> */}
                            <div className="col-md-4">
                                <div className="pricing-plan price-layout-1 bg-darkgrey">
                                    <div className="icon-styled fs-50">
                                        <i className="color-darkgrey ico ico-gems" />
                                    </div>
                                    <div className="plan-name">
                                        <img src="images/gallery/service-2.jpg" />
                                        <div className="plan-description" style={{ paddingTop: "20px" }}><h5>NLP (Neurolinguistic programming)</h5></div>
                                        <p className='word-limit'>Neurolinguistic Programming (NLP) is a psychotherapeutic approach that explores the connection between language, neurological processes, and behavior. It aims to modify learned patterns, enhance communication, and foster self-awareness. NLP techniques empower individuals to reshape cognitive responses for improved communication and personal growth.</p>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="pricing-plan price-layout-1 bg-darkgrey">
                                    <div className="icon-styled fs-50">
                                        <i className="color-darkgrey ico ico-gems" />
                                    </div>
                                    <div className="plan-name">
                                        <img src="images/gallery/service-3.jpg" />
                                        <div className="plan-description" style={{ paddingTop: "20px" }}><h5>Numerology</h5></div>
                                        <p className='word-limit'>Numerology is a metaphysical practice that ascribes significance to numbers, attributing unique vibrations to influence aspects of human life. By analyzing numerical values from names and birthdates, it provides insights into personality, destiny, and life path. Numerology serves as a tool for self-discovery, offering guidance for personal growth and decision-making.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="pricing-plan price-layout-1 bg-darkgrey">
                                    <div className="icon-styled fs-50">
                                        <i className="color-darkgrey ico ico-gems" />
                                    </div>
                                    <div className="plan-name">
                                        <img src="images/gallery/service-4.jpg" />
                                        <div className="plan-description" style={{ paddingTop: "20px" }}><h5>Vastu Visit</h5></div>
                                        <p className='word-limit'>Vastu Visit involves examining the energy flow in a physical space, aligning it with Vastu Shastra principles for balance and harmony. This traditional Indian architectural practice focuses on spatial arrangements and orientations to enhance well-being and prosperity. Vastu Visits offer guidance on optimizing living or workspaces for positive energy flow and auspiciousness.</p>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="pricing-plan price-layout-1 bg-darkgrey">
                                    <div className="icon-styled fs-50">
                                        <i className="color-darkgrey ico ico-gems" />
                                    </div>
                                    <div className="plan-name">
                                        <img src="images/gallery/service-5.jpg" />
                                        <div className="plan-description" style={{ paddingTop: "20px" }}><h5>Tarot Card</h5></div>
                                        <p className='word-limit'>Tarot cards are a deck of symbolic cards used for divination, offering insights into the past, present, and future. Each card carries specific meanings and represents aspects of life, emotions, and spiritual paths. Tarot readings provide a tool for self-reflection, guidance, and exploring the unseen forces influencing personal and spiritual journeys.</p>
                                    </div>

                                </div>
                            </div>

                        </Slider>
                    </div>
                </div>
            </section >

        </>
    )
}

export default Services;