import React from "react";

const AboutUs = () => {
    return (
        <>
            <div className="about-us-page container" >
                <h3>About us</h3>
                <p> Our Storied Odyssey: A Celestial Saga of Wisdom and Achievement! ????</p>
                <p>Embark on a Journey with the Pioneers of Cosmic Insight!</p>
                <p>For a remarkable 16 years, our astrological maestros have been crafting destinies with unparalleled precision.
                    Picture this: a cosmic symphony conducted by an M.Sc. graduate, adorned with the prestigious Gold Medal in Astrology & Lalkitab.</p>
                <p>Guided by the Stars, Mentored by the Best</p>
                <p>Under the illustrious guidance of none other than the trailblazing Ms. Kiran Bedi, the first woman IPS officer, our visionary
                    counselors have not only honed their skills but have also contributed as esteemed professors in both Degree Colleges and Universities.</p>
                <p>Decoding the Cosmic Blueprint: From Stars to Finances</p>
                <p>Ever wondered if the alignment of stars could reveal the intricacies of your personality, behavior, or even your deepest perceptions? We not
                    only wonder—we specialize in it. Our expertise extends to Lalkitab, offering unparalleled solutions to reshape the very fabric of your character,
                    transforming weaknesses into strengths and paving the way for the life you desire.</p>
                <p>Financial Alchemy: Where Stars Meet Prosperity</p>
                <p>In the cosmic ballet of life, your financial status takes center stage. Earnings, business ventures, loans—every note of your financial symphony
                    is choreographed by the stars. Our astrological virtuosos decipher the celestial dance, providing insights that transcend mundane predictions.
                    Unleash the potential within your horoscope to unlock the doors to prosperity.</p>
                <p>Why Settle for Ordinary, When You Can Embrace the Extraordinary?</p>
                <p>Join us on a celestial journey where stars aren't just distant luminaries but the architects of your destiny. With a legacy steeped in excellence and
                    a commitment to transforming lives, we invite you to experience the cosmic grandeur that is uniquely ours.</p>
                <p>Welcome to a realm where your story is written in stardust, and your destiny is crafted by cosmic hands. Embrace the extraordinary—your celestial
                    adventure begins here! ????????</p>
            </div>
        </>
    )
}

export default AboutUs;