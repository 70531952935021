import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

const ServicesPage = () => {
  return (
    <>
      <section id="shop" className="ds section-shop s-pb-60">
        <div className="container">
          <div className="row">
            <h3 className="mt-0 mb-2 special-heading fs-60 color-main">
              <span style={{ marginTop: "30px", textAlign: "center" }}>
                All Services
              </span>
            </h3>
          </div>

          <div className="row">
            <div className="col-md-4" style={{ marginBottom: "15px" }}>
              <div className="pricing-plan price-layout-1 bg-darkgrey">
                <div className="icon-styled fs-50">
                  <i className="color-darkgrey ico ico-diamond" />
                </div>
                <div className="plan-name">
                  <img src="/images/gallery/service-1.jpg" />
                  <div
                    className="plan-description"
                    style={{ paddingTop: "20px" }}
                  >
                    <h5>Digitalised Analysis of Horoscope</h5>
                  </div>
                  <p>
                    "Unlock the secrets of your astrological chart with our
                    Digitalized Analysis of Horoscope service. Receive a
                    comprehensive, personalized report that provides insights
                    into your personality, relationships, career, and future,
                    all based on the precise positions of celestial bodies at
                    your birth."
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="pricing-plan price-layout-1 bg-darkgrey">
                <div className="icon-styled fs-50">
                  <i className="color-darkgrey ico ico-gems" />
                </div>
                <div className="plan-name">
                  <img src="/images/gallery/service-2.jpg" />
                  <div
                    className="plan-description"
                    style={{ paddingTop: "20px" }}
                  >
                    <h5>NLP (Neurolinguistic programming)</h5>
                  </div>
                  <p>
                    "Enhance your personal and professional life through
                    Neurolinguistic Programming (NLP). Our expert-guided
                    sessions help you understand and reprogram your thoughts,
                    behaviors, and communication patterns, enabling you to
                    achieve your goals and improve your overall well-being."
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="pricing-plan price-layout-1 bg-darkgrey">
                <div className="icon-styled fs-50">
                  <i className="color-darkgrey ico ico-gems" />
                </div>
                <div className="plan-name">
                  <img src="/images/gallery/service-3.jpg" />
                  <div
                    className="plan-description"
                    style={{ paddingTop: "20px" }}
                  >
                    <h5>Numerology</h5>
                  </div>
                  <p>
                    "Discover the power of numbers with our Numerology service.
                    Gain deeper insights into your life path, personality
                    traits, and destiny by analyzing the numerical vibrations of
                    your name and birth date, guiding you towards a more
                    fulfilling and balanced life."
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="pricing-plan price-layout-1 bg-darkgrey">
                <div className="icon-styled fs-50">
                  <i className="color-darkgrey ico ico-gems" />
                </div>
                <div className="plan-name">
                  <img src="/images/gallery/service-4.jpg" />
                  <div
                    className="plan-description"
                    style={{ paddingTop: "20px" }}
                  >
                    <h5>Vastu Visit</h5>
                  </div>
                  <p>
                    "Optimize the energy flow in your living or working space
                    with our Vastu Visit service. Our Vastu experts provide
                    on-site consultations to align your environment with ancient
                    architectural principles, promoting health, happiness, and
                    prosperity."
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="pricing-plan price-layout-1 bg-darkgrey">
                <div className="icon-styled fs-50">
                  <i className="color-darkgrey ico ico-gems" />
                </div>
                <div className="plan-name">
                  <img src="/images/gallery/service-5.jpg" />
                  <div
                    className="plan-description"
                    style={{ paddingTop: "20px" }}
                  >
                    <h5>Tarot Card</h5>
                  </div>
                  <p>
                    "Seek guidance and clarity with our Tarot Card reading
                    service. Our experienced tarot readers offer insightful
                    readings to help you navigate life's challenges, uncover
                    hidden opportunities, and make informed decisions
                    about your future."
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServicesPage;
