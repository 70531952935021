import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom'
import HomePage from './Components/HomePage/HomePage';
import Header from './HOC/Header';
import Footer from './HOC/Footer';
import AboutUs from './Components/AboutUs/AboutUs';
import PrivacyPolicy from './Components/Policy/PrivacyPolicy';
import TermsCondition from './Components/Policy/TermsCondition';
import Disclaimer from './Components/Disclaimer/Disclaimer';
import ContactUs from './Components/ContactUs/ContactUs';
import Blog from './Components/Blog/Blog';
import BookAppoinment from './Components/BookAppoinment/BookAppoinment';
import ServicesPage from './Components/Services/ServicesPage';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path='/' element={ <HomePage />} />
          <Route path='/about-us' element={<AboutUs />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/terms-condition' element={<TermsCondition />} />
          <Route path='/disclaimer' element={<Disclaimer />} />
          <Route path='/contact-us' element={<ContactUs />} />
          <Route path='/services' element={<ServicesPage />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/book-appoinment' element={<BookAppoinment />} />
        </Routes>
        <Footer />
      </BrowserRouter>

    </div>
  );
}

export default App;
