import React from 'react';
import Slider from "react-slick";

const Testimonials = () => {
    var settings = {
        dots: true,
        autoplay: false,
        infinite: true,
        arrows: false,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [{
            breakpoint: 800,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: false
            }
        }]
    };
    return (
        <>
            <section id="shop" className="ds section-shop s-pb-60">
                <div className="container testimonials-slider">
                    <div className="row">
                        <div className="text-center">
                            <h3 className="text-center mt-0 mb-2 special-heading fs-60 color-main">
                                <span>What Our Clients Says</span>
                            </h3>
                            <div className="line" />
                            <div className="divider-40 divider-xl-60" />
                        </div>
                    </div>
                    <div className='row'>
                        <Slider {...settings}>
                            <div className="col-md-4"> 
                                <div className="pricing-plan price-layout-1 bg-darkgrey">
                                    <div className="icon-styled fs-50">
                                        <i className="color-darkgrey ico ico-diamond" />
                                    </div>
                                    <div className="plan-name">
                                        <div className='text-center'>
                                            <img src="images/services/poojatiwari.webp" width={80} />
                                            <div className="plan-description" style={{ paddingTop: "20px", }}><h5 className='text-center'>Ritika Verma</h5></div>
                                            <p style={{marginBottom: "10px"}} >Rohini Sec 18, Delhi, India</p>
                                        </div>
                                        <p className='word-limit'>Thanks Para Astro for being available at my difficult time and motivating me to get through that phase. I am finally doing good now after Navgrah Pooja performed by them. The complete process was very smooth. I'll recommend it to my friends.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="pricing-plan price-layout-1 bg-darkgrey">
                                    <div className="icon-styled fs-50">
                                        <i className="color-darkgrey ico ico-gems" />
                                    </div>
                                    <div className="plan-name">
                                        <div className='text-center'>
                                            <img src="images/services/prabharani.webp" width={80} />
                                            <div className="plan-description" style={{ paddingTop: "20px" }}><h5>Pinki Verma</h5></div>
                                            <p style={{marginBottom: "10px"}} >Viman Nagar, Pune, India</p>
                                        </div>
                                        <p className='word-limit'>I was not getting married and felt depressed. One day I installed Astrotalk and took their live sessions. The astrologer told me about the reason I couldn't get married. I quickly took his advice and worked on getting rid of the dosh and to my surprise after 6 months I found my soulmate on the matrimonial website. Right now, I am happily married.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="pricing-plan price-layout-1 bg-darkgrey">
                                    <div className="icon-styled fs-50">
                                        <i className="color-darkgrey ico ico-gems" />
                                    </div>
                                    <div className="plan-name">
                                        <div className='text-center'>
                                            <img src="images/services/pinkiagarwalla.webp" width={80} />
                                            <div className="plan-description" style={{ paddingTop: "20px" }}><h5>Puja Tiwari</h5></div>
                                            <p style={{marginBottom: "10px"}}> Civil Lines, Lucknow, India</p>
                                        </div>
                                        <p className='word-limit'>Consulted an Astrologer for child name's based on nakshatras. The service was good at a reasonable price and they were very patient and clear during the session. The names were suggested according to astrology and numerology and we got an awesome name for our baby boy.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="pricing-plan price-layout-1 bg-darkgrey">
                                    <div className="icon-styled fs-50">
                                        <i className="color-darkgrey ico ico-gems" />
                                    </div>
                                    <div className="plan-name">
                                        <div className='text-center'>    
                                            <img src="images/services/testi-2.webp" width={80} />
                                            <div className="plan-description" style={{ paddingTop: "20px" }}><h5>Reena</h5></div>
                                            <p style={{marginBottom: "10px"}}>gaur City, Noida, India</p>
                                        </div>
                                        <p className='word-limit'>Para Astro is really helpful for me in seeking astrology advice from England at any time. Thanks Guys, I am really happy with the service and support I get from customer support and Astrologers. Everyone is so friendly and helpful.Very good experience with the app so far.</p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-md-4">
                                <div className="pricing-plan price-layout-1 bg-darkgrey">
                                    <div className="icon-styled fs-50">
                                        <i className="color-darkgrey ico ico-gems" />
                                    </div>
                                    <div className="plan-name">
                                        <div className='text-center' style={{ margin: "auto" }}>
                                            <img src="images/services/poojatiwari.webp" width={80} />
                                            <div className="plan-description" style={{ paddingTop: "20px" }}><h5>Rohit Kapoor</h5></div>
                                            <p style={{marginBottom: "10px"}}>Char Minar, Hydrabad, India</p>
                                        </div>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p>
                                    </div>
                                </div>
                            </div> */}
                        </Slider>
                    </div>
                </div>
            </section >

        </>
    )
}

export default Testimonials;