import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaBars } from 'react-icons/fa';
import Drawer from 'react-modern-drawer'

//import styles 👇
import 'react-modern-drawer/dist/index.css'

const Header = () => {
    const [isOpen, setIsOpen] = React.useState(false)
    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState)
    }
    return (
        <>
            <div class="header_absolute">
                <section className="page_topline ds s-py-10 hide-in-mobile">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-6 col-lg-4 col-md-4 text-lg-left text-center">
                                <a href="index.html" className="logo">
                                    <Link to="/"><img src="images/astro-logo.png" alt="img" /></Link>
                                </a>
                            </div>
                            <div className="col-xl-6 col-lg-8 col-md-8 some-links text-center text-lg-right d-flex justify-content-center justify-content-md-end align-items-center mt-2 mt-md-0">
                                <a
                                    href="https://wa.me/9818194431" target='_blank'
                                    className="btn btn-medium  d-none d-md-block"
                                    style={{ background: "#fff", color: "#000", fontSize: "16px", fontWeight: "500" }}
                                >
                                    <i class="fa fa-whatsapp" aria-hidden="true" style={{ color: "green", marginRight: "5px", fontSize: "20px" }}></i>
                                    Let's Chat

                                </a>
                                <Link
                                    to="/book-appoinment"
                                    className="btn btn-outline-maincolor btn-medium  d-none d-md-block"
                                >
                                    <i class="fa fa-calendar" style={{ marginRight: "5px" }}></i>
                                    Book Appointment

                                </Link>
                            </div>
                        </div>
                    </div>
                </section>

                <div className='burger-menu' >
                    <div>
                        <a href="index.html" className="logo">
                            <Link to="/"><img src="images/astro-logo.png" alt="img" /></Link>
                        </a>
                    </div>
                    <div>
                        <FaBars className="burger-icon" onClick={toggleDrawer} />
                        <Drawer
                            open={isOpen}
                            onClose={toggleDrawer}
                            direction='right'
                            className='bla bla bla'
                        >
                            <ul>
                                <Link to="/book-appoinment"><li>Book an Appointment</li></Link>
                                <Link to="/contact-us"><li>Contact Us</li></Link>
                                <Link to="/services"><li>Serivces</li></Link>
                                <Link to="/services">
                                    <li><button style={{color: "#fff", background: "#000", border: "none", borderRadius: "30px", padding: "5px 20px 5px"}}><i class="fa fa-whatsapp" aria-hidden="true" style={{ color: "green", marginRight: "5px", fontSize: "20px" }}></i>Let's Chat</button></li>
                                </Link>
                            </ul>

                        </Drawer>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header;