import axios from "axios";
import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import toast, { Toaster } from "react-hot-toast";

const BookAppoinment = () => {
  const [services, setServices] = useState("Digitalised Analysis of Horoscope");
  const [fullName, setFullName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [timeSlot, setTimeSlot] = useState("1(am) - 2(am)");
  const [chooseDate, setChooseDate] = useState("");
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = () => {
    const obj = {
      service: services,
      customerData: {
        fullName: fullName,
        email: email,
        phoneNumber: number,
      },
      myAvailability: {
        date: chooseDate,
        timeSlot: timeSlot,
      },
    };
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (services.length === 0) {
      console.log("hello toast");
      // setNameError("Fullname is required.");
      return toast.error("Services is required.");
    }
    if (fullName.length === 0) {
      console.log("hello toast");
      // setEmailError("Email is required.");
      return toast.error("Fullname is required.");
    }
    if (email.length === 0) {
      toast.error("Email is required");
      return false;
    } else if (!emailRegex.test(email)) {
      toast.error("Invalid email format");
      return false;
    }
    if (number.length === 0) {
      toast.error("Mobile Number is Required");
      return false;
    } else if (number.length < 10) {
      toast.error("Mobile Number should be at least 10 digits long");
      return false;
    }
    if (chooseDate.length === 0) {
      console.log("hello toast");
      // setMessageError('Message is required.')
      return toast.error("Date is required.");
    }
    if (timeSlot.length === 0) {
      console.log("hello toast");
      // setMessageError('Message is required.')
      return toast.error("Time slot is required.");
    }
    setLoading(true);
    axios
      .post(`https://naazapps.pythonanywhere.com/para/save-appointment`, obj)
      .then((res) => {
        console.log("Response:", res);
        if (res.data.success === false) {
          console.log("Error in response:", res.config.data);
        } else if (res.data.success === true) {
          console.log("Success:", res);
          toast.success(res.data.message);
          setNumber("");
          setChooseDate("");
          setFullName("");
          setEmail("");
          setServices("Digitalised Analysis of Horoscope");
          setTimeSlot("1(am) - 2(am)");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
        setLoading(false);
        // Handle generic error
      });
  };

  return (
    <>
      <div className="container book-appoinment-page">
        <Toaster position="top-center" reverseOrder={false} />
        <h2 className="">Book Appoinment</h2>
        <div className="row">
          <div className="col-md-6">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Choose a Service*</Form.Label>
              <Form.Select onChange={(e) => setServices(e.target.value)}>
                <option value="Digitalised Analysis of Horoscope">
                  Digitalised Analysis of Horoscope
                </option>
                <option value="NLP (Neurolinguistic programming)">
                  NLP (Neurolinguistic programming)
                </option>
                <option value="Numerology">Numerology</option>
                <option value="Vastu Visit">Vastu Visit</option>
                <option value="Vastu Visit">Tarot Card</option>
              </Form.Select>
            </Form.Group>
          </div>
          <div className="col-md-6">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Full Name*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Full Name*"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Phone Number*</Form.Label>
              <Form.Control
                type="number"
                maxlength="10"
                pattern="\d{10}"
                value={number}
                placeholder="Contact Number*"
                onChange={(e) => {
                  if (e.target.value.length > 10) {
                    return;
                  }
                  setNumber(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Time Slot*</Form.Label>
              <Form.Select
                value={timeSlot}
                onChange={(e) => setTimeSlot(e.target.value)}
              >
                <option value="1(am) - 2(am)">1(am) - 2(am)</option>
                <option value="2(am) - 3(am)">2(am) - 3(am)</option>
                <option value="3(am) - 4(am)">3(am) - 4(am)</option>
                <option value="4(am) - 5(am)">4(am) - 5(am)</option>
                <option value="5(am) - 6(am)">5(am) - 6(am)</option>
                <option value="6(am) - 7(am)">6(am) - 7(am)</option>
                <option value="7(am) - 8(am)">7(am) - 8(am)</option>
                <option value="8(am) - 9(am)">8(am) - 9(am)</option>
                <option value="9(am) - 10(am)">9(am) - 10(am)</option>
                <option value="10(am) - 11(am)">10(am) - 11(am)</option>
                <option value="11(am) - 12(am)">11(am) - 12(am)</option>
                <option value="1(pm) - 2(pm)">1(pm) - 2(pm)</option>
                <option value="2(pm) - 3(pm)">2(pm) - 3(pm)</option>
                <option value="3(pm) - 4(pm)">3(pm) - 4(pm)</option>
                <option value="4(pm) - 5(pm)">4(pm) - 5(pm)</option>
                <option value="5(pm) - 6(pm)">5(pm) - 6(pm)</option>
                <option value="6(pm) - 7(pm)">6(pm) - 7(pm)</option>
                <option value="7(pm) - 8(pm)">7(pm) - 8(pm)</option>
                <option value="8(pm) - 9(pm)">8(pm) - 9(pm)</option>
                <option value="9(pm) - 10(pm)">9(pm) - 10(pm)</option>
                <option value="10(pm) - 11(pm)">10(pm) - 11(pm)</option>
                <option value="11(pm) - 12(pm)">11(pm) - 12(pm)</option>
              </Form.Select>
            </Form.Group>
          </div>
          <div className="col-md-6">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>E-mail</Form.Label>
              <Form.Control
                type="email"
                value={email}
                placeholder="Enter Email address*"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Choose Appoinment Date</Form.Label>
              <Form.Control
                type="date"
                value={chooseDate}
                placeholder="Enter Birth City, Town*"
                onChange={(e) => setChooseDate(e.target.value)}
              />
            </Form.Group>
          </div>
        </div>
        <div className="book-now">
          <button
            className="padding-1"
            disabled={isLoading}
            onClick={handleSubmit}
          >
            {isLoading ? <Spinner animation="border" /> : "Book Now"}
          </button>
        </div>
      </div>
    </>
  );
};

export default BookAppoinment;
