import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { Spinner } from "react-bootstrap";

const ContactUs = () => {
  const [fullName, setFullName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [nameError, setNameError] = useState("");
  const [numberError, setNumberError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = () => {
    const obj = {
      fullName: fullName,
      email: email,
      contactNumber: number,
      subject: subject,
      message: message,
    };
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (fullName.length === 0) {
      console.log("hello toast");
      setNameError("Fullname is required.");
      return toast.error("Fullname is required.");
    }
    if (email.length === 0) {
        toast.error("Email is required");
        return false;
      } else if (!emailRegex.test(email)) {
        toast.error("Invalid email format");
        return false;
      }
    if (number.length === 0) {
      toast.error("Mobile Number is Required");
      setNumberError("Number is required.");
      return false;
    } else if (number.length < 10) {
      toast.error("Mobile Number should be at least 10 digits long");
      return false;
    }
    if (subject.length === 0) {
      console.log("hello toast");
      setSubjectError("Subject is required.");
      return toast.error("Subject is required.");
    }
    if (message.length === 0) {
      console.log("hello toast");
      setMessageError("Message is required.");
      return toast.error("Message is required.");
    }
    setLoading(true);

    axios
      .post(`https://naazapps.pythonanywhere.com/para/contact-us`, obj)
      .then((res) => {
        console.log("Response:", res);
        if (res.data.success === false) {
          console.log("Error in response:", res.config.data);
          setNameError(res.config.data.fullName);
        } else if (res.data.success === true) {
          console.log("Success:", res);
          toast.success(res.data.message);
          setEmail("");
          setFullName("");
          setSubject("");
          setMessage("");
          setNumber("");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
        setLoading(false);
        // Handle generic error
      });
  };

  return (
    <>
      <div className="container contact-us-page">
        <Toaster position="top-center" reverseOrder={false} />
        <h2>Contact Us</h2>
        <div className="row">
          <div className="col-md-6">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Full Name*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Full Name*"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </Form.Group>
            <small style={{ color: "red" }}>{nameError}</small>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Contact Number*</Form.Label>
              <Form.Control
                type="number"
                maxlength="10"
                pattern="\d{10}"
                value={number}
                placeholder="Contact Number*"
                onChange={(e) => {
                  if (e.target.value.length > 10) {
                    return;
                  }
                  setNumber(e.target.value);
                }}
              />
            </Form.Group>
            <small style={{ color: "red" }}>{numberError}</small>
          </div>
          <div className="col-md-6">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email Address*</Form.Label>
              <Form.Control
                type="email"
                placeholder="name@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <small style={{ color: "red" }}>
              {emailError ? emailError : ""}
            </small>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Subject*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Subject/Reason *"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </Form.Group>
            <small style={{ color: "red" }}>{subjectError}</small>
          </div>
        </div>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>Message*</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter Message*"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </Form.Group>
        <small style={{ color: "red" }}>{messageError}</small>
        <Form.Group
          className="mb-3"
          controlId="exampleForm.ControlInput1"
          style={{ display: "flex" }}
        >
          <Form.Check aria-label="option 1" />
          <Form.Label style={{ marginLeft: "10px" }}>
            {" "}
            I accept Privacy Policy.
          </Form.Label>
        </Form.Group>
        <button disabled={isLoading} onClick={handleSubmit}>
          {isLoading ? <Spinner animation="border" /> : "Submit"}
        </button>
      </div>
    </>
  );
};

export default ContactUs;
